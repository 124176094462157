<template>
  <div class="index">
    <div class="box" v-for="item in list" :key="item">
      <div class="content" :id="item.anchor">
        <p class="name">{{ item.name }}</p>
        <img class="img" :src="item.img" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "professor",
  data() {
    return {
      list: [
        {
          img: require("@/assets/02.png"),
          name: "主办单位",
          anchor: "a",
        },
        {
          img: require("@/assets/02.png"),
          name: "培训目标",
          anchor: "b",
        },
        {
          img: require("@/assets/02.png"),
          name: "培训内容",
          anchor: "c",
        },
        {
          img: require("@/assets/02.png"),
          name: "培训对象",
          anchor: "d",
        },
        {
          img: require("@/assets/02.png"),
          name: "近期开班",
          anchor: "e",
        },
        {
          img: require("@/assets/02.png"),
          name: "相关须知",
          anchor: "f",
        },
        {
          img: require("@/assets/02.png"),
          name: "学员好评",
          anchor: "g",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.index {
  .box {
    width: 1920px;
    height: 470px;
    background-color: #eeeeee;
    margin-left: 15px;
    padding-top: 50px;
    box-sizing: border-box;
    .content {
      width: 1200px;
      margin: 0 auto;
      .name {
        margin-top: 80px;
        font-size: 36px;
        text-align: center;
        color: #333;
      }
      .img {
        margin-top: 60px;
        width: 1200px;
        height: 140px;
      }
    }
  }
  .box:nth-child(2n+1){
    background-color: #fff;
  }
}
</style>