<template>
  <div class="index">
    <div class="box">
      <div class="a" v-for="item in list" :key="item">
        <div class="left">
          <span class="one"> {{ item.name }} </span>
          <span class="two"> {{ item.name }} </span>
          <span class="three"> {{ item.introduce }} </span>
          <span class="four"> {{ item.name }} </span>
        </div>
        <img class="img" :src="item.img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "professor",
  data() {
    return {
      list: [
        {
          img: require("@/assets/02.png"),
          name: "专家一专家一",
          introduce: "专家简介简介专家简介简介专家简介简专家简介简介专家简介简介专家简介简专家简介简介专家简介简介专家简介简专家简介简介专家简介简介专家简介简专家简介简介专家简介简介专家简介简",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.index {
  .box {
    width: 1920px;
    height: 470px;
    background-color: #eeeeee;
    margin-left: 15px;
    padding-top: 50px;
    box-sizing: border-box;
    .a {
      width: 1200px;
      height: 360px;
      margin: 0 auto;
      .one {
        margin-bottom: 10px;
        display: block;
        font-size: 18px;
        color: #0e81bd;
      }
      .two {
        display: block;
        margin-bottom: 10px;
        font-size: 34px;
      }
      .three {
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
        width: 670px;
        line-height: 28px;

      }
      .four {
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
        width: 210px;
        border-top: 3px solid #e68725;
        color:#e68725;
        text-align: center;
        height: 26px;
        line-height: 26px;
      }
      .left {
        float: left;
      }
      .img {
        width: 500px;
        height: 360px;
        float: right;
      }
    }
  }
}
</style>